import React from "react";
import { StatusLight } from "../UI/StatusLight";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { TableCell, TableRow } from "@/components/ui/table";

let timeclaim1 = [];

export default function Job(props) {

  const temp = localStorage.getItem("temp");
  const { claim } = props;
  const { timeclaim } = props;

  timeclaim1 = timeclaim.data.data?.filter(function (tcxj) {
    return tcxj.claim === claim.id;
  });

  const dateUTC = new Date(claim.created_at + 'Z')
  const currentdate = dateUTC.toLocaleString(
    "en-CA",
    {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }
  );

  if (claim !== undefined) {
    return (
      <TableRow>
        <TableCell onClick={() => (window.location.href = "/lareas/" + claim.id)}>
          <p className="text-base font-semibold">
            {claim.address.split(",")[0]}
          </p>
          <p className="text-sm font-normal text-muted-foreground">
            {claim.address.substring(claim.address.indexOf(",") + 1)}{" "}
          </p>
          <p className="text-sm font-normal text-muted-foreground">
            Claim number:{claim.claimnumber}{" "}
          </p>
        </TableCell>
        <TableCell >
          <div className="flex flex-col">
            {claim.status === "N" ? (
              <div className="flex flex-row items-center text-base font-medium">
                {" "}
                <StatusLight status="progress" disabled={false} />
                In progress
              </div>
            ) : (
              <div className="flex flex-row items-center text-base font-medium">
                {" "}
                <StatusLight status="finished" disabled={false} />
                Finished
              </div>
            )}
            <div>
              <p className="text-sm font-normal text-muted-foreground">
                Elapsed: {parseFloat(timeclaim1[0]?.tlacl).toFixed(2)}h
              </p>
            </div>
          </div>
        </TableCell>
        <TableCell >
          <div className="text-sm font-normal text-muted-foreground">
            {currentdate}
          </div>
        </TableCell>

        {temp > "1" ? (
          <TableCell></TableCell>
        ) : (
          <TableCell>
            <div className="text-sm text-muted-foreground">{claim.name}</div>
          </TableCell>
        )}

        <TableCell className="align-middle">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0 ">
                <span className="sr-only">Open menu</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>Actions</DropdownMenuLabel>
              <DropdownMenuItem
                onClick={() => (window.location.href = "/lareas/" + claim.id)}
              >
                List Areas
              </DropdownMenuItem>

              {claim.status !== "F" && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() => (window.location.href = "/ejob/" + claim.id + "/" + claim.contractor_id)}
                  >
                    Edit Job
                  </DropdownMenuItem>
                </>
              )}

              {claim.status !== "F" && claim.status === "N" && (
                <>
                  {claim.status !== "F" && <DropdownMenuSeparator />}
                  <DropdownMenuItem
                    onClick={() =>
                      (window.location.href = "/addArea/" + claim.id)
                    }
                  >
                    Add Area
                  </DropdownMenuItem>
                </>
              )}

              {claim.status !== "F" && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() =>
                      (window.location.href =
                        "/genpub/" + claim.contractor_id + "/" + claim.id)
                    }
                  >
                    Generate QR
                  </DropdownMenuItem>
                </>
              )}

              {claim.status === "F" && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    onClick={() =>
                      (window.location.href = "/certificate2/" + claim.id)
                    }
                  >
                    Generate Certificate
                  </DropdownMenuItem>
                  {temp === '1' && (
                    <DropdownMenuItem
                      onClick={() =>
                        (window.location.href = "/lcosts/" + claim.id)
                      }
                    >
                      Check Costs
                    </DropdownMenuItem>
                  )}
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </TableCell>
      </TableRow>
    );
  }
}
